import Vue from 'vue';

const firebaseConfig = {
    apiKey: 'AIzaSyBvCakgYEfNYKjyxHr1_XI5s4gM2-4zAzY',
    authDomain: 'unicorn-4f512.firebaseapp.com',
    databaseURL: 'https://unicorn-4f512-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'unicorn-4f512',
    storageBucket: 'unicorn-4f512.appspot.com',
    messagingSenderId: '329043180786',
    appId: '1:329043180786:web:c4f6f7cc1f34671c2a646b',
};

Vue.mixin({
    data: function () {
        return {
            get companyName() {
                return 'UNICORN'; //公司名稱
            },
            get regFormDomain() {
                return 'https://unicorn-hk.xyz/';
            },
        };
    },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = '#e8f2fa';

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = '#000';

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
